<template>
  <div class="main">
    <!-- header end-->
    <!-- body start -->
    <div class="bodybox" >
      <div class="textbox">
        <div>
          <!-- <div class="app-local">
            <div class="sr-grid">
                <span>当前位置：</span><a href="./index.html">首页</a>
                <i class="iconfont">&#xe661;</i><span>招聘服务</span>
            </div>
        </div> -->
          <div class="layout-content service-ct">
            <div class="sr-grid has-gap36">
              <div id="filter" class="app-filter">
                <div class="tagbox" :style="iswelfare ? '': ' max-height: 70px;'">
                  <div class="tagleft" @click="getwelfare">
                    福利待遇 <i v-if="!iswelfare" class="el-icon-arrow-down"></i>
                    <i v-else class="el-icon-arrow-up"></i>
                  </div>
                  <div class="tagright">
                    <span
                      v-for="(item, index) in typelist"
                      :key="index"
                      :class="indexaa.indexOf(item.Name) == -1 ? '' : 'active'"
                      @click="choeseaa(item.Name)"
                      >{{ item.Name }}</span
                    >
                  </div>
                </div>

                <div class="tagbox" :style="isrecType ? '': ' max-height: 70px;'">
                  <div class="tagleft"  @click="getrecType">
                    招聘类型 <i v-if="! isrecType" class="el-icon-arrow-down"></i>
                    <i v-else class="el-icon-arrow-up"></i>
                  </div>
                  <div class="tagright">
                    <span
                      v-for="(item, index) in zhaopintypelist"
                      :key="index"
                      :class="item.SysID == Type ? 'active' : ''"
                      @click="choesebb(item.SysID)"
                      >{{ item.Name }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sr-grid">
            <h5 style="margin-top: 20px">
              <span style="font-size: 24px; color: #333">招聘职位</span>
            </h5>
            <div class="sswarp">
              <el-input
                placeholder="请输入内容"
                v-model="KeyWords"
                class="input-with-select"
              >
                <el-button
                  slot="append"
                  @click="getsearch()"
                  icon="el-icon-search"
                ></el-button>
              </el-input>
              <!-- <input type="text" placeholder="请输入企业/职位" class="zpinp"> -->
            </div>
            <div class="zwwarp">
              <div
                class="box"
                v-for="(item, i) in list"
                :key="i"
                @click="getinfor(item)"
              >
                <div class="a">
                  <div class="titlebox">
                    {{ item.Title }}
                  </div>
                  <div style="margin-top: 20px">
                    <span v-if="item.str" class="tab">{{ item.str }}</span
                    ><i v-if="item.str">|</i>
                    <span class="tab" v-if="item.Experience">{{
                      item.Experience
                    }}</span
                    ><i v-if="item.Experience">|</i
                    ><span class="tab" v-if="item.Education">{{
                      item.Education
                    }}</span
                    ><i v-if="item.Education">|</i
                    ><span class="tab">{{
                      item.SalaryL + "-" + item.SalaryH
                    }}</span>
                  </div>
                  <div class="gstb">
                    <img :src="item.CompanyLogo" alt="" />
                    <span>{{ item.CompanyName }}</span>
                  </div>
                </div>

                <div class="gswz">
                  <span>{{ item.ViewCount }}人查看></span>
                </div>
                <div class="gswz">
                  <span>{{ item.ContactCount }}人沟通></span>
                </div>
                <div class="gswz">
                  <span style="font-size: 10px"
                    >{{ item.PublishTime }}发布</span
                  >
                </div>

                <div class="btnBox">
                  <div>
                    <span
                      style="color: #3fbcbb; margin-left: 10px; cursor: pointer"
                      >查看</span
                    >
                  </div>
                </div>
                <!-- <div class="btnBox" style="align-items:center;">
                        <span style="color: #888;cursor: pointer;margin-left: 20px;line-height: 30px;font-weight: 800;">· · · </span>

                    </div> -->
              </div>
              <div class="more" v-if="IsShowMore == 1">
                <span @click="GetPageMore()"
                  >加载更多 <i class="el-icon-d-arrow-right"></i
                ></span>
              </div>
              <div class="more" v-if="IsShowMore != 1 && list.length">
                <em>已加载全部 </em>
              </div>
              <div class="more" v-if="IsShowMore != 1 && !list.length">
                <em>暂无数据 </em>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- body end -->
  </div>
</template>

<script>
// 公共样式

import "../assets/css/common.css";
import timeFormat from "../util/time.js";
export default {
  data() {
    return {
      list: [],
      activeIndex: "7",
      drawer: false,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      navlist: [
        {
          names: "首页",
          path: "/",
          id: "1",
        },
        {
          names: "政策资讯",
          path: "/news",
          id: "2",
        },
        {
          names: "产业载体",
          path: "/carrier",
          id: "3",
        },
        {
          names: "企业互动",
          path: "/interaction",
          id: "4",
        },
        {
          names: "金融服务",
          path: "/service",
          id: "5",
        },
        {
          names: "企业服务",
          path: "/enterpriseServices",
          id: "6",
        },
        {
          names: "招聘服务",
          path: "/recruit",
          id: "7",
        },
      ],
      arr: ["zt1", "zt2", "zt3", "zt4", "zt5", "zt6", "zt7", "zt8"],
      aa: ["不限", "周末双休", "五险一金", "包吃住", "带薪年假"],
      indexaa: [],
      bb: ["不限", "综合", "电商", "文化创意", "互联网"],
      indexbb: 0,
      height: "400px",
      input2: "",
      KeyWords: "",
      zhaopintypelist: [],
      Tags: "",
      Type: "",
      typelist: [],
      PageIndex: 1,
      PageSize: 8,
      IsShowMore: 1,
      isrecType: false,
      iswelfare:false,
    };
  },

  watch: {
    windowWidth(val) {
      let that = this;
      // console.log("实时屏幕宽度：", val);
      if (val > 990) {
        that.drawer = false;
        that.height = "400px";
      } else if (val <= 900 && val > 400) {
        that.height = "200px";
      } else {
        that.height = "100px";
      }
    },
  },
  methods: {
    
     getwelfare() {
      this.iswelfare = !this.iswelfare;
    },
    // 招聘类型
    getrecType() {
      this.isrecType = !this.isrecType;
    },
    GetPageMore() {
      this.PageIndex = this.PageIndex + 1;
      this.getnewsList();
    },
    getsearch() {
      this.PageIndex = 1;
      this.getnewsList();
    },
    // 获取新闻列表
    getnewsList() {
      let that = this;
      let KeyWords = that.KeyWords;
      let Tags = that.indexaa;
      Tags = Tags.join(",");
      let Type = that.Type;
      that
        .$post("/api/Advertise/List", {
          KeyWords,
          Tags,
          Type,
          State: true,
          CState: true,
          PageIndex: that.PageIndex,
          PageSize: that.PageSize,
          Sort: "CreateOn desc",
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          list.forEach((v) => {
            if (v.CompanyLogo) {
              v.CompanyLogo = that.urlOA + v.CompanyLogo.substr(1);
            }
            if (v.CreateOn) {
              v.PublishTime = timeFormat.timeFormat(v.PublishTime, 1);
            }
            if (v.Address) {
              v.str = v.Address.substring(0, 2);
            }
            that.list.push(v);
          });
          if (that.PageIndex == 1) {
            that.list = list;
          } else if (that.PageIndex > 1) {
            that.list = that.list.concat(list);
          }
          if (data.Obj.length == that.PageSize) {
            that.IsShowMore = 1;
          } else {
            that.IsShowMore = 0;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 招聘类型
    getzhaopintype() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "AdvertiseType",
          Sort: "Sort asc",
          State: true,
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.zhaopintypelist = list.filter((z) => z.State);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 福利待遇

    gettype() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "Benefits",
          Sort: "Sort asc",
          State: true,
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.typelist = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getinfor(item) {
      this.$router.push({
        path: "/RecruitmentDetails",
        query: { SysID: item.SysID },
      });
    },
    choeseaa(index) {
      let that = this;
      console.log(index);
      let indexaa = that.indexaa;
      let a = indexaa.indexOf(index);
      console.log(a);
      if (a == -1) {
        indexaa.unshift(index);
      } else {
        indexaa.splice(a, 1);
      }
      that.PageIndex = 1;

      that.getnewsList();
    },
    choesebb(index) {
      console.log(index);
      if (index === this.Type) {
        this.Type = "";
      } else {
        this.Type = index;
      }
      this.PageIndex = 1;

      this.getnewsList();
    },
    handleSelect(e) {
      let that = this;
      console.log(e);
      that.$router.push(e);
      setTimeout(() => {
        that.drawer = false;
      }, 500);
    },
    getlogin() {
      this.$router.push("/login");
    },
    register() {
      this.$router.push("/register");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  mounted() {
    var that = this;
    that.getnewsList();
    that.getzhaopintype();
    that.gettype();
    let windowWidth = that.windowWidth;
    console.log(windowWidth);
    if (windowWidth < 900 && windowWidth > 400) {
      that.height = "200px";
    } else if (windowWidth < 400) {
      that.height = "150px";
    }
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; // 高
        that.windowWidth = window.fullWidth; // 宽
      })();
    };
  },
};
</script>


<style scoped  lang="scss">
.tagbox {
  display: flex;
  border-bottom: 1px dashed #ccc;
  padding: 5px 0;
  .tagleft {
    flex: 1;

    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 700;
    margin-top: 10px;
    overflow: hidden;
    i{
      display: none;
    }
  }
  .tagright {
    flex: 9;
    span {
      float: left;
      margin-right: 30px;
      width: 100px;
      text-align: center;
      padding: 3px 0;
      cursor: pointer;
      font-size: 15px;
      border-radius: 4px;
      margin-top: 10px;
    }
  }
}

.titlebox {
  width: 100%;
  font-size: 17px;
  margin-right: 115px;
  font-weight: 700;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.gstb {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-items: center;
  float: left;
  width: 100%;
  font-size: 16px;
  span {
    margin-left: 10px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.gstb img {
  width: 50px;
  vertical-align: middle;
}

.gswz {
  flex: 1;
  display: flex;
  color: #888;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
}

.btnBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gswz img {
  width: 20px;
}

.zplist {
  margin-top: 100px;
  overflow: hidden;
  width: 262px;
  height: 40px;
  border: 1px solid #ccc;
  margin: 30px auto;
  border-radius: 20px;
}

.after,
.before {
  float: left;
  width: 70px;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.after {
  border-right: 1px solid #ccc;
}

.page1 {
  float: left;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-right: 1px solid #ccc;
}

.zaction {
  color: #fff;
  background-color: #3fbcbb;
}

.gwzz {
  margin-top: 20px;
  width: 800px;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
}

.gwzz li {
  line-height: 40px;
  font-size: 16px;
}

.gsjj {
  overflow: hidden;
  width: 800px;
  font-size: 16px;
  line-height: 28px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
  border-radius: 5px;
  margin-top: 20px;
  padding: 20px;
}

.gsjj .img {
  width: 70px;
  height: 70px;
  border: 1px solid #ccc;
  float: left;
}

.gsjj .name {
  font-size: 20px;
  line-height: 70px;
  float: left;
  margin-left: 20px;
}

.qybq {
  text-align: center;
  width: 100px;
  padding: 5px;
  border: 1px solid #ccc;
  height: 40px;
  line-height: 30px;
  border-radius: 2px;
  font-size: 16px;
  float: left;
  margin-right: 10px;
  margin-top: 10px;
}

.sendjl {
  cursor: pointer;
  margin-top: 110px;
  float: right;
  /* width: 200px; */
  padding: 0 20px;
  height: 40px;
  background-color: #3fbcbb;
  color: #fff;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  border-radius: 4px;
}
.ss {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
}

.zwwarp {
  width: 100%;
  overflow: hidden;
}

.zwwarp .box {
  /* margin-top: 30px; */
  margin: 0;
  width: 100%;
  /* margin-left: 4%; */
  // height: 200px;
  /* float: left; */
  display: flex;
  padding: 30px;
  /* border-radius: 20px; */
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  box-shadow: none;
  box-sizing: border-box;
}

.zwwarp .box:hover {
  background-color: #f5f8f8;
  /* color: #fff !important; */
}
.zwwarp .box .a {
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #333;
}
.tab {
  display: inline-block;
  padding: 0 8px;
  font-size: 12px;
  /* border-right: 1px solid #ccc; */
  /* background-color: #3FBCBB; */
  color: #888;
  /* margin-right: 10px; */
  height: 20px;
  line-height: 20px;
  /* border-radius: 3px; */
}

.box i {
  font-style: normal;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  width: 2px;
  font-size: 12px;
  /* background-color: #ccc; */
  color: #888;
  margin-left: 5px;
  margin-right: 5px;
}

.sswarp {
  padding: 20px 0;
  width: 50%;
}

// 内容区

.textbox {
  width: 1230px;
  margin-left: 50%;
  transform: translateX(-50%);
  // background-color: #475669;
  // padding-top: 100px;
  overflow: hidden;
}

.aaa {
  display: none;
}
@media (max-width: 1201px) {
  .textbox {
    width: 940px;
  }
  .zwwarp .box {
    width: 910px;
  }
  h5 {
    padding: 10px;
    box-sizing: border-box;
  }
  .sswarp {
    width: 300px;
    padding-left: 10px;
  }
  .row_item {
    float: left;
    display: block;
    width: 191px;
    height: 110px;

    margin-bottom: 20px;
    margin-left: 36px;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
}
@media (max-width: 991px) {
  .app-filter dl {
    padding: 10px;
    box-sizing: border-box;
  }
  .app-filter dt {
    margin-bottom: 10px;
    width: 100%;
  }
  .app-filter ul li {
    display: inline-block;
    width: 80px;
    text-align: center;
  }
  .aaa {
    display: block;
  }

  .bannerbox img {
    height: 100%;
  }
  .main .textbox {
    width: 100%;
    overflow: hidden;
    padding-top: 10px;
  }
  .textleft,
  .textright {
    float: left;
    width: 100%;
    overflow: hidden;
    h4 {
      text-align: center;
    }
  }
  .item {
    position: relative;
    height: 120px;
    width: 100%;
    img {
      position: absolute;
      left: 0;
      top: 0;
      height: 120px;
      width: 30%;
    }
    .newsbox {
      position: absolute;
      left: 35%;
      height: 100%;
      width: 60%;
      padding: 6px;
      box-sizing: border-box;
      h3 {
        position: absolute;
        top: 0;
        font-size: 16px;
        height: 40px;
        line-height: 20px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      p {
        position: absolute;
        height: 40px;
        line-height: 20px;
        top: 50px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .itembottom {
        position: absolute;
        height: 20px;
        bottom: 0;
        line-height: 20px;
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 500px) {
  .zwwarp .box {
    width: 100%;
    padding: 20px;
  }
  .gswz {
    display: none;
  }
  .zwwarp .box a {
    flex: 3;
  }
  .btnBox {
    // flex: 2;
    font-size: 12px;
  }
  .tagbox {
    display: flex;
    border-bottom: 1px dashed #ccc;
    padding: 20px 0;
   
    overflow: hidden;
    box-sizing: border-box;
    .tagleft {
      flex: 1;
      justify-content: center;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      margin-top: 10px;
      i{
        display: block;
        font-weight: 700;
        margin-left: 4px;
      }
    }
    .tagright {
      flex: 3;
      span {
        float: left;
        margin-right: 10px;
        width: 100px;
        
        text-align: center;
        padding: 3px 0;
        cursor: pointer;
        font-size: 14px;
        border-radius: 4px;
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }
  }
}
</style>